import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, FormControl, AbstractControl, UntypedFormBuilder, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EmailValidators } from 'ngx-validators'
import { ToastrService } from 'ngx-toastr';
import { AppGlobal } from 'src/app/services/app.global';
import { ApiService } from 'src/app/services/api.service';
import { GvarService } from 'src/app/services/gvar.service';

@Component({
    selector: 'app-paymenterror',
    templateUrl: './paymenterror.component.html',
    styleUrls: ['./paymenterror.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaymentErrorComponent implements OnInit {
    public router: Router;

    data: any;
    dataResponse: any;
    constructor(router: Router, private fb: FormBuilder,
        private toastr: ToastrService,
        private config: AppGlobal,
        private API: ApiService,
        private GV: GvarService,
        private activatedRoute: ActivatedRoute,) {
        //this.data = this.activatedRoute.snapshot.params["id"];
        this.activatedRoute.queryParams.subscribe(params => {
            this.data = params['data'];
        });
        this.router = router;
    }
    ngOnInit() {
        this.gatData();
    }


    gatData() {
        this.API.getPayment(this.config.UPDATE_INVOICE_ERROR + this.data).subscribe({
            next: (data) => {
                if (data != null) {
                    this.dataResponse = data;
                }
            },
            error: (error) => {
                if (error.error != undefined) {
                    this.toastr.warning(error.error.Message, 'Alert');
                }
            }
        });
    }
}
