<div class="container">
    <div class="vh-100 d-flex justify-content-center align-items-center">
        <div>
            <div class="mb-4 text-center">
                <svg *ngIf="hideShow" xmlns="http://www.w3.org/2000/svg" class="text-success" width="75" height="75"
                    fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16" style="color: #4BAE4F">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>

                <svg *ngIf="!hideShow" xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="currentColor"
                    class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16" style="color: #E4A11B">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>
            </div>
            <div class="text-center">
                <h1 *ngIf="hideShow" class="text-success">Thank You !</h1>
                <h1 *ngIf="!hideShow" style="color: #E4A11B">Alert !</h1>
                <!-- <p>Response Code: {{dataResponse.responseCode}}</p> -->
                <p><b>Payment Type: {{dataResponse.PaymentType}}</b></p>
                <p>{{dataResponse.responseMessage}}</p>
                <!-- <button class="btn btn-primary">Back Home</button> -->
            </div>
        </div>
    </div>