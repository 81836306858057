import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/dashboard', null, 'tachometer', null, false, 0),

]

export const horizontalMenuItems = [
    new Menu(1, 'Dashboard', '/dashboard', null, 'tachometer', null, false, 0),

]