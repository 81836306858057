import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Menu } from '../theme/components/menu/menu.model';
import { RolesRequestModel } from './roles';
@Injectable({
  providedIn: 'root'
})
export class GvarService {

  // Hire in Search Data
  agencyID: string;
  ALCode: string;
  airportID: string;
  catID: string;
  fromDate: string;
  ToDate: string;
  hasValue: boolean;
  requestSearch: any;
  // End
  GoodsCallFrom: string;
  private Roles: RolesRequestModel[]
  G_IsRunning: boolean = false;
  locationID: number;
  userName: string;
  UserId: string;
  serverURL: string = environment.serverURL;
  serverURLLogin: string = environment.serverURLLogin;
  constructor() {
    this.userName = (localStorage.getItem('userName'));
    this.UserId = (localStorage.getItem('UserId'));
  }
  roleMatch(allowedRoles): boolean {
    var temp = (localStorage.getItem('menuItems'));
    if (temp == "undefined") {
      return false
    }

    this.Roles = JSON.parse(localStorage.getItem('menuItems'));
    for (var i = 0; i < this.Roles.length; i++) {
      if (allowedRoles == this.Roles[i].canAdd) {
        return true;
      }

    }
    return false
  }

  // Dynamic menu code

  setMenuItems(val: any) {
    const menuItems = localStorage.setItem('menuItems', JSON.stringify(val));
    return menuItems;
  }
  getMenuItems() {
    const getRoleAccess = JSON.parse(localStorage.getItem('menuItems'));
    return getRoleAccess;
  }
  removeMenuItems() {
    const removeMenuItems = localStorage.removeItem('menuItems');
    return removeMenuItems;
  }
  generateMenuItems(items) {
    const menu_items = [];
    for (let i = 0; i < items.length; i++) {
      menu_items[i] = new Menu(
        items[i].module_id,
        items[i].module_title,
        items[i].module_routerLink,
        items[i].module_href,
        items[i].module_icon,
        items[i].module_target,
        items[i].module_hasSubMenu,
        items[i].module_parentId);

    }
    return menu_items;
  }

  // Dynamic menu code end

  get canAdd() {
    return this.roleMatch(true);
  }

  get canEdit() {
    return this.roleMatch(true);
  }
  get canDelete() {
    return this.roleMatch(true);
  }
  get canView() {
    return this.roleMatch(true);
  }

}
