<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <i class="fa fa-user-o" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            <div class="user-info text-center p-3">
                <img src="assets/img/users/user.jpg" alt="user-img" class="user-img rounded-circle">
                <p class="mt-1 mb-0">
                    {{UserName}}
                    <small>{{UserId}}</small>
                </p>
            </div>
            <!-- <a class="dropdown-item" routerLink="/profile"><i class="fa fa-user mr-2"></i>Profile</a>
            <a class="dropdown-item" routerLink="/"><i class="fa fa-cog mr-2"></i>Settings</a>
            <a class="dropdown-item" routerLink="/"><i class="fa fa-lock mr-2"></i>Lock screen</a> -->
            <a class="dropdown-item mb-1" (click)="openLogoutModal(logout)"><i class="fa fa-power-off mr-2"></i>Log
                out</a>

        </div>
    </div>
</div>


<!--Logout Confirmation Model-->
<ng-template #logout let-modal>
    <div class="modal-header modal_head">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" class="close cross" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="outline:none">
            <span aria-hidden="true" class="whitee">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center m-2">
        <p style="margin-bottom: 0; font-size: 14px;">Are you sure you want to logout?</p>
    </div>
    <div class="card-footer text-center">
        <button class="movebtn movebtnsu" (click)="onClickLogout()">Confirm</button>
        <button class="movebtn movebtdng ml-2" (click)="modal.close('Close click')">
            Cancel
        </button>
    </div>
</ng-template>