export class AppGlobal {
    //Login API
    public readonly TOKEN_AUTH = "/User/Auth";

    //Group Roles API
    public readonly GET_GROUPS = "/FZ/getGroups";
    public readonly SAVE_GROUPS = "/FZ/saveGroup";
    public readonly GET_GROUP_ROLES = "/FZ/getgroupRoles?groupid=";

    //Users API
    public readonly SAVE_USERS = "/FZ/createUser";
    public readonly GET_USERS = "/FZ/getUsers";

    //Clients API
    public readonly SAVE_CLIENTS = "/FZ/saveClient";
    public readonly GET_CLIENTS = "/FZ/getClients";
    public readonly GET_COUNTRIES = "/FZ/getCountries";

    //Invoices API
    public readonly GET_STATUS = "/FZ/getStatus";
    public readonly GET_CLIENTS_BY_NUMBER = "/FZ/getClientByPhone?phone=";
    public readonly SAVE_INVOICE = "/FZ/saveInvoice";
    public readonly GET_INVOICES = "/FZ/getInvoices";
    public readonly GET_INVOICES_BY_INVOICEID = "/FZ/getInvoiceDetail?invoiceId=";
    public readonly GET_INVOICE_DETAIL = "/FZ/GetInvoiceDetails?invoiceId=";

    //Email API
    public readonly SEND_EMAIL = "/FZ/sendEmail";

    //Dashboard API
    public readonly GET_DASHBOARD_DATA = "/FZ/GetDashboard";
    public readonly UPDATE_INVOICE = "/api/Final/Thankyou?data=";
    public readonly UPDATE_INVOICE_ERROR = "/api/Final/Error?data=";
}