import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { Auth } from './services/guard.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {
    path: '',
    component: PagesComponent,
    canActivate: [Auth],
    children: [
      { path: 'index', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' } },
      { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } },
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
      { path: 'grouproles', loadChildren: () => import('./pages/group-roles/groups.module').then(m => m.GroupsModule), data: { breadcrumb: 'Group Roles' } },
      { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule), data: { breadcrumb: 'Clients' } },
      { path: 'invoice', loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule), data: { breadcrumb: 'Invoice' } },
      { path: 'invoicedetail', loadChildren: () => import('./pages/invoice-details/invoice-details.module').then(m => m.InvoiceDetailsModule), data: { breadcrumb: 'Invoice Detail' } },
      { path: 'invoicedetail/:id', loadChildren: () => import('./pages/invoice-details/invoice-details.module').then(m => m.InvoiceDetailsModule), data: { breadcrumb: 'Invoice Detail' } },
      { path: 'invoice/:id', loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule), data: { breadcrumb: 'Invoice' } },

    ]
  },

  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'thankyou', loadChildren: () => import('./pages/thankyou/thankyou.module').then(m => m.ThanyouModule) },
  { path: 'paymentError', loadChildren: () => import('./pages/paymentError/paymenterror.module').then(m => m.PaymentErrorModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      useHash: true,
    })

  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }