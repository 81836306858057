import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, FormControl, AbstractControl, UntypedFormBuilder, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EmailValidators } from 'ngx-validators'
import { ToastrService } from 'ngx-toastr';
import { AppGlobal } from 'src/app/services/app.global';
import { ApiService } from 'src/app/services/api.service';
import { GvarService } from 'src/app/services/gvar.service';

@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ThankyouComponent implements OnInit {
    hideShow: boolean = true;
    public router: Router;
    data: any;
    dataResponse: any;
    dataUrl: any;
    constructor(router: Router, private fb: FormBuilder,
        private toastr: ToastrService,
        private config: AppGlobal,
        private API: ApiService,
        private GV: GvarService,
        private activatedRoute: ActivatedRoute,) {
        //this.data = this.activatedRoute.snapshot.params["id"];
        this.activatedRoute.queryParams.subscribe(params => {
            this.data = params['data'];
        });
        this.router = router;
        this.dataUrl = "http://raspay.com.pk/Services" + this.config.UPDATE_INVOICE + this.data;
    }
    ngOnInit() {
        this.gatData();
    }
    gatData() {
        this.API.getPayment(this.config.UPDATE_INVOICE + this.data).subscribe({
            next: (data) => {
                if (data != null) {
                    this.dataResponse = data;
                    if (this.dataResponse.responseCode == 100 || this.dataResponse.responseCode == 0) {
                        this.hideShow = true;
                    }
                    else {
                        this.hideShow = false;
                    }
                }
            },
            error: (error) => {
                if (error.error != undefined) {
                    this.toastr.warning(error.error.Message, 'Alert');
                }
            }
        });
    }
}
