import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  modalRef: NgbModalRef;
  constructor(private modalService: NgbModal, private router: Router, private authService: AuthService,) { }
  UserName: any;
  UserId: any;
  ngOnInit() {
    this.UserName = localStorage.getItem('name');
    this.UserId = localStorage.getItem('userID');
  }
  onClickLogout() {
    this.modalRef.close();
    this.authService.Logout();
    this.router.navigateByUrl('/login');
  }
  openLogoutModal(content) {
    this.modalRef = this.modalService.open(content, { centered: false, backdrop: 'static', size: 'sm' });

  }
}
